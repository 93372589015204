import { Header } from "src/components/header";
import { Footer } from "src/components/Footer";
import { Banner } from "./components/banner";
import { Nav } from "./components/nav";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Work } from "./components/work";

const Home = () => {
  return (
    <div className="bg-site bg-cover overflow-hidden">
      <Header />
      <Banner />
      <Nav />
      <About />
      <Services />
      <Work />
      <Footer />
    </div>
  );
};

export default Home;
