import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-scroll";
import { downloadResume } from "src/utils/downloadResume";

const Footer = () => {
  return (
    <div className="bg-black/50 flex lg:flex-row flex-col gap-y-6 lg:gap-y-0 justify-evenly pb-24 pt-10">
      <div className="flex flex-col items-center">
        Links Úteis
        <div className="mt-6 gap-y-4 flex flex-col items-center">
          <Link to="home" smooth={true} spy={true}>
            <span className="hover:underline cursor-pointer">Home</span>
          </Link>
          <Link to="about" smooth={true} spy={true}>
            <span className="hover:underline cursor-pointer">Sobre mim</span>
          </Link>
          <Link to="services" smooth={true} spy={true}>
            <span className="hover:underline cursor-pointer">
              Habilidades/Histórico
            </span>
          </Link>
          <Link to="work" smooth={true} spy={true}>
            <span className="hover:underline cursor-pointer">Projetos</span>
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center">
        Bem vindo(a) ao meu Portfólio, o que achou?<br />
        Me chame no LinkedIn, vamos nos conectar!
      </div>
      <div className="flex flex-col items-center">
        Contato
        <div className="pt-4 flex flex-col gap-y-4 items-center">
          <div className="flex text-[20px] gap-x-4 max-w-max mx-auto lg:mx-0">
            <a
              href="https://www.linkedin.com/in/jackson-antunes01/"
              target="_blank"
              rel="noreferrer"
              className="hover:scale-125 transition-all duration-200"
            >
              <FaLinkedin size={35} />
            </a>
          </div>
          <button className="btn btn-sm btn-three" onClick={downloadResume}>
            Baixar currículo
          </button>
        </div>
      </div>
    </div>
  );
};

export { Footer };
