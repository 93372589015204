import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { TypeAnimation } from "react-type-animation";
import { BsArrowDownShort } from "react-icons/bs";

import { fadeIn } from "src/variants";
import Image from "src/assets/about.png";
import { openLink } from "src/utils/openLink";

const Banner = () => {
  return (
    <section id="home" className="section-banner">
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-8 lg:flex-row items-center lg:gap-x-12">
          <motion.div
            variants={fadeIn("down", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            className="max-w-[30vh] lg:max-w-[50vh]"
          >
            <motion.div
              variants={fadeIn("down", 5)}
              initial="hidden"
              whileInView={"show"}
              className="-mt-20"
            >
              <Link
                className="animated-arrow"
                activeClass="bg-transparent"
                to="products"
                smooth={true}
                spy={true}
              >
                Esse sou eu!
                <BsArrowDownShort size={50} />
              </Link>
            </motion.div>
            <img
              className="rounded-full cursor-pointer transition-all transform hover:scale-95"
              src={Image}
              alt=""
              onClick={() =>
                openLink("https://www.linkedin.com/in/jackson-antunes01/")
              }
            />
          </motion.div>
          <div className="flex flex-1 flex-col text-center items-center justify-center">
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true }}
              className="text-[55px] font-bold leading-[0.8] lg:text-[110px] lg:mb-6"
            >
              Jackson <span>Antunes</span>
            </motion.h1>
            <motion.div
              variants={fadeIn("up", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true }}
              className="mb-6 text-[36px] lg:text-[50px] font-secondary font-semibold uppercase leading-[1]"
            >
              <span className="text-white mr-4">
                Desenvolvedor
                <br />
              </span>
              <TypeAnimation
                sequence={["React Native", 2000, "React", 2000, "NodeJS", 2000]}
                speed={50}
                className="text-accent"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true }}
              className="flex max-w-max gap-x-6 items-center mx-auto lg:mx-0"
            >
              <Link to="contact" smooth={true} spy={true}>
                <button className="btn btn-lg btn-three">
                  Entrar em contato
                </button>
              </Link>
              <Link to="about" smooth={true} spy={true}>
                <button className="btn btn-lg btn-three">Sobre mim</button>
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Banner };
