import { motion } from "framer-motion";

import { fadeIn } from "src/variants";
import RandomClock from "src/assets/random_clock.png";
import PetHood from "src/assets/pethood.png";
import MaturityCard from "src/assets/marutity_card.png";
import { openLink } from "src/utils/openLink";

const worksLeft = [
  {
    name: "Random Clock - Alarme Simples",
    description:
      "Aplicativo de alarme em horários aleatórios.",
    link: "https://play.google.com/store/apps/details?id=com.randomclock&hl=pt_BR",
    image: RandomClock,
  },
];

const worksRight = [
  {
    name: "Pet Hood - Adoção Consciente",
    description:
      "Aplicativo para facilitar o processo de adoção de animais de estimação.",
    link: "",
    image: PetHood,
  },
  {
    name: "Maturity Card",
    description: "Criação do site em VueJS.",
    link: "https://game.maturitycard.com/",
    image: MaturityCard,
  },
];

const Work = () => {
  return (
    <section id="work" className="section">
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-x-10">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            className="flex-1 flex flex-col gap-y-0 mb-10 lg:mb-0"
          >
            <div>
              <h2 className="h2 leading-tight text-accent font-semibold">
                Meus últimos Apps
              </h2>
              <div className="mb-8 text-justify">
                <span className="text-accent font-semibold">
                  {"Pet Hood - Adoção Consciente (Em desenvolvimento): "}
                </span>
                Criei o layout em figma e estou criando o app em React Native com o foco em facilitar o processo para encontrar um novo animal de estimação.
                <br />
                <span className="text-accent font-semibold">{"Random Clock: "}</span>
                Aplicativo de alarme aleatório com o objetivo de receber mensagens motivacionais em horários (adivinha?) aleatórios haha
                <br />
                <span className="text-accent font-semibold">
                  {"Maturity Card: "}
                </span>
                Desenvolvi o front-end do site utilizando Vue.JS
              </div>
              <div className="flex flex-row justify-center lg:justify-start">
                <button
                  className="btn btn-three btn-lg mb-0 xl:mb-5 lg:mb-8"
                  onClick={() =>
                    openLink(
                      "https://github.com/Jack-antunes-01?tab=repositories"
                    )
                  }
                >
                  Ver todos os projetos
                </button>
              </div>
            </div>
            {worksLeft.map((item, index) => {
              const { description, link, name, image } = item;
              return (
                <div
                  key={index}
                  className="group relative overflow-hidden border-2 border-white/50 rounded-xl cursor-pointer h-[350px] lg:mb-10"
                  onClick={() => openLink(link)}
                >
                  <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                  <img
                    className="group-hover:scale-125 transition-all duration-500 w-full h-full object-cover"
                    alt=""
                    src={image}
                  />
                  <div className="absolute -bottom-full left-12 group-hover:bottom-10 transition-all duration-500 z-50">
                    <p className="text-[24px] font-bold">{name}</p>
                    <p className="text-white">{description}</p>
                  </div>
                </div>
              );
            })}
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            className="flex-1 flex flex-col gap-y-10"
          >
            {worksRight.map((item, index) => {
              const { description, image, link, name } = item;
              return (
                <div
                  key={index}
                  className="group relative overflow-hidden border-2 border-white/50 rounded-xl h-[350px]"
                  onClick={() => openLink(link)}
                >
                  <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                  <img
                    className="group-hover:scale-125 transition-all duration-500 w-full h-full object-cover"
                    alt=""
                    src={image}
                  />
                  <div className="absolute -bottom-full left-12 group-hover:bottom-10 transition-all duration-500 z-50">
                    <p className="text-[24px] font-bold">{name}</p>
                    <p className="text-white">{description}</p>
                  </div>
                </div>
              );
            })}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export { Work };
