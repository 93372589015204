import { FaGithub, FaLinkedin } from "react-icons/fa";

import Logo from "src/assets/logo.png";
import { downloadResume } from "src/utils/downloadResume";
import { openLink } from "src/utils/openLink";

const Header = () => {
  return (
    <header className="bg-black/50">
      <div className="container mx-auto flex flex-row justify-between">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => openLink("https://www.jasoftwaredevelopment.com.br")}
        >
          <img src={Logo} alt="" className="w-[100px] h-[100px]" />
        </div>
        <div className="py-8 flex flex-row lg:gap-x-8 gap-x-2 items-center">
          <div className="flex text-[20px] lg:gap-x-6 gap-x-2 max-w-max mx-auto lg:mx-0">
            <a
              href="https://github.com/Jack-antunes-01"
              target="_blank"
              rel="noreferrer"
              className="hover:scale-125 transition-all duration-200"
            >
              <FaGithub size={35} />
            </a>
            <a
              href="https://www.linkedin.com/in/jackson-antunes01/"
              target="_blank"
              rel="noreferrer"
              className="hover:scale-125 transition-all duration-200"
            >
              <FaLinkedin size={35} />
            </a>
          </div>
          <button className="btn btn-lg btn-three" onClick={downloadResume}>
            Baixar currículo
          </button>
        </div>
      </div>
    </header>
  );
};

export { Header };
