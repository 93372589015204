import React from "react";
import { motion } from "framer-motion";
import { Tooltip } from "@material-tailwind/react";

import { fadeIn } from "src/variants";

import NodeLogo from "src/assets/node.png";
import ReactLogo from "src/assets/react.png";
import FlutterLogo from "src/assets/flutter.png";
import VueLogo from "src/assets/vue.png";
import AngularLogo from "src/assets/angular.png";
import KotlinLogo from "src/assets/kotlin.png";
import { Stars } from "src/components/Stars";
import { Link } from "react-scroll";

const services = [
  {
    title: "Claro Brasil",
    subTitle: "Desenvolvedor Mobile",
    description:
      "Trabalhei no aplicativo da maior empresa de telecomunicação do Brasil. Minha Claro Móvel, aplicativo com + de 1M usuários por dia.",
    link: "https://play.google.com/store/apps/details?id=com.nvt.cs&hl=pt_BR&gl=US",
    linkDescription: "Acessar",
  },
  {
    title: "Objective",
    subTitle: "Desenvolvedor Mobile/Full Stack",
    description:
      "Atuei pela Claro e, posteriormente, contribuí desenvolvendo o front de um projeto interno chamado Maturity Card.",
    link: "https://game.maturitycard.com/",
    linkDescription: "Acessar",
  },
  {
    title: "Grão Direto",
    subTitle: "Desenvolvedor Full Stack",
    description:
      'Atuei no desenvolvimento & manutenção do produto "Clicou Fechou!".',
    link: "https://www.graodireto.com.br/",
    linkDescription: "Acessar",
  },
  {
    title: "Invest Me",
    subTitle: "Desenvolvedor Mobile",
    description:
      "Trabalhei na criação do site e do aplicativo da empresa em React Native e, posteriormente, em Flutter.",
    link: "https://www.investme.com.br/",
    linkDescription: "Acessar",
  },
  {
    title: "Uniube",
    subTitle: "Desenvolvedor Full Stack",
    description: "Atuei na criação de ferramentas para alunos e professores.",
    link: "https://uniube.br/",
    linkDescription: "Acessar",
  },
];

const knowledge = [
  {
    name: "React Native",
    level: 5,
  },
  {
    name: "TypeScript",
    level: 5,
  },
  {
    name: "Git",
    level: 5,
  },
  {
    name: "Testes Unitários",
    level: 5,
  },
  {
    name: "NodeJS",
    level: 4,
  },
  {
    name: "React",
    level: 3,
  },
  {
    name: "Flutter",
    level: 3,
  },
  {
    name: "Kotlin",
    level: 2,
  },
];

const Services = () => {
  return (
    <section id="services" className="section">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            className="flex-1 mb-12 lg:mb-0"
          >
            <h2 className="h2 text-accent mb-6 font-semibold">Tecnologias</h2>
            Principais:
            <div className="mt-6 mb-6 flex flex-row gap-6 items-center">
              <Tooltip content={"React Native"}>
                <img
                  src={ReactLogo}
                  className="w-[100px] h-[100px] hover:scale-110 transition-all duration-200 "
                  alt=""
                />
              </Tooltip>
              <Tooltip content={"NodeJS"}>
                <img
                  src={NodeLogo}
                  className="w-[100px] h-[100px] hover:scale-110 transition-all duration-200 "
                  alt=""
                />
              </Tooltip>
            </div>
            Secundárias:
            <div className="mt-6 mb-6 flex flex-row gap-6 items-center">
              <Tooltip content={"React"}>
                <img
                  src={ReactLogo}
                  className="w-[50px] h-[50px] hover:scale-110 transition-all duration-200 "
                  alt=""
                />
              </Tooltip>
              <Tooltip content={"VueJS"}>
                <img
                  src={VueLogo}
                  className="w-[50px] h-[50px] hover:scale-110 transition-all duration-200 "
                  alt=""
                />
              </Tooltip>
              <Tooltip content={"Flutter"}>
                <img
                  src={FlutterLogo}
                  className="w-[50px] h-[50px] hover:scale-110 transition-all duration-200 "
                  alt=""
                />
              </Tooltip>
              <Tooltip content={"Angular"}>
                <img
                  src={AngularLogo}
                  className="w-[50px] h-[50px] hover:scale-110 transition-all duration-200 "
                  alt=""
                />
              </Tooltip>
              <Tooltip content={"Kotlin"}>
                <img
                  src={KotlinLogo}
                  className="w-[50px] h-[50px] hover:scale-110 transition-all duration-200 "
                  alt=""
                />
              </Tooltip>
            </div>
            <div className="mb-6">
              {knowledge.map((item, index) => {
                const { level, name } = item;
                return (
                  <div className="flex flex-row items-center" key={index}>
                    <div className="w-full justify-between lg:justify-start">
                      {name}
                    </div>
                    <Stars filled={level} />
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row w-full justify-center">
              <Link to="work" smooth={true} spy={true}>
                <button className="btn btn-three btn-lg">Ver mais</button>
              </Link>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            className="flex-1"
          >
            <div>
              {services.map((service, index) => {
                const { description, link, title, subTitle, linkDescription } =
                  service;
                return (
                  <div
                    key={index}
                    className="border-b border-white/20 pb-[16px] mb-[16px] flex"
                  >
                    <div className="max-w-[476px] mr-4">
                      <h4 className="text-[20px] tracking-wider text-accent font-bold">
                        {title}
                      </h4>
                      <h4 className="leading-tight tracking-wider font-primary font-semibold mb-4">
                        {subTitle}
                      </h4>
                      <p className="leading-tight text-justify">
                        {description}
                      </p>
                    </div>
                    <div className="flex flex-col flex-1 items-end gap-2">
                      <a
                        href={link}
                        target="_blank"
                        className="btn btn-lg btn-three"
                        rel="noreferrer"
                      >
                        {linkDescription}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export { Services };
