import CountUp from "react-countup";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

import { fadeIn } from "src/variants";
import AboutImage from "src/assets/me.png";
import { openLink } from "src/utils/openLink";

const About = () => {
  return (
    <section id="about" className="section">
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            className="flex-1"
          >
            <h2 className="h2 text-accent font-semibold">Sobre mim</h2>
            <h3 className="h3 mb-4 leading-tight">
              Olá! Tudo bem contigo? Sente-se que lá vem história!
            </h3>
            <div className="text-justify mb-6">
              Meu nome é Jackson, tenho 22 anos.
              <br />
              Trabalho como Desenvolvedor de Software desde os 18 anos.
              <br />
              Possuo bacharelado em Sistemas de Informação pela Uniube e estou cursando
              MBA em Desenvolvimento Mobile Multiplataforma pela XP Educação.
              <br />
              Sou o tipo de desenvolvedor que resolve problemas utilizando linguagens de programação que não passam de ferramentas no meu dia-a-dia,
              pois acredito que ser desenvolvedor está muito além de linhas de código.
              <br />
              Estou constantemente em busca de oportunidades para
              crescer pessoal e profissionalmente.
              <br />
              Como desenvolvedor, sou apaixonado pela troca de experiências com outros desenvolvedores.
            </div>
            <div className="flex gap-x-6 lg:gap-x-10 mb-12 justify-center">
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  <CountUp start={0} end={4} duration={3} />+
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Anos de <br />
                  Experiência
                </div>
              </div>
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  <CountUp start={0} end={7} duration={3} />+
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Projetos <br />
                  Completados
                </div>
              </div>
            </div>
            <div className="flex gap-x-8 items-center justify-center">
              <Link
                to="services"
                smooth={true}
                spy={true}
                className="btn btn-three btn-lg"
              >
                Ver mais
              </Link>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            className="hidden lg:flex flex-1 max-w-[550px] mix-blend-lighten"
          >
            <img
              className="rounded-full cursor-pointer transition-all transform hover:scale-95"
              src={AboutImage}
              alt=""
              onClick={() =>
                openLink("https://www.linkedin.com/in/jackson-antunes01/")
              }
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export { About };
